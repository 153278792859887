<template>
  <v-layout row class="login-form">
    <div class="overlay">
    <div class="form-outline">
      <v-card class="remove-shadow">
        <v-card-title>Change Password</v-card-title>
        <v-card min-width="370" min-height="554" class="mx-auto card-color">
          <v-form class="text-filed-container" ref="passwordForm"
          @submit.prevent="emitChangePassword">
              <div class="mb-2">
                <span> New Password </span>
              </div>
              <div>
                <v-text-field
                  v-model="newPassword"
                  placeholder="Password"
                  single-line
                  outlined
                  :append-icon="newPassShow ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="newPassShow ? 'text' : 'password'"
                  @click:append="newPassShow = !newPassShow"
                  :rules="[v => v.length > 0 || '*Required*']"
                  :error="error"
                  :error-messages="errorNewPassMessages"
                ></v-text-field>
              </div>
              <div class="mb-2">
                <span> Confirm Password </span>
              </div>
              <div>
                <v-text-field
                  v-model="confirmPassword"
                  placeholder="Password"
                  single-line
                  outlined
                  :append-icon="confirmPassShow ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="confirmPassShow ? 'text' : 'password'"
                  @click:append="confirmPassShow = !confirmPassShow"
                  :rules="[v => v.length > 0 || '*Required*']"
                  :error="errorConfirm"
                  :error-messages="errorConfirmPassMessages"
                ></v-text-field>
              </div>
              <v-col>
                <Button
                  type="submit"
                  value="Change Password"
                  color="primary"
                  height="40px"
                  width="100%"
                  :disabled="disabled"
                />
                <v-row justify="center">
                  <Button
                    class="margin-top-1"
                    type="link"
                    @buttonClicked="goToSignUp"
                    value="Don't have an account yet ? Please Sign Up"
                  />
                </v-row>
              </v-col>
            </v-form>
          </v-card>
      </v-card>
      <div v-if="openDialog">
        <v-dialog v-model="openDialog" max-width="370px">
          <Dialog
            :dialogData="toasterMessage"
            :onCloseDialog="closeDialog"
            mode="snackbar-error"
          />
        </v-dialog>
      </div>
    </div>
    </div>
  </v-layout>
</template>

<script>
import {
  SNACKBAR_TIMEOUT,
  HTTP_STATUS_CODE,
  TOASTER_MESSAGE,
} from '@/constants';
import Dialog from '@/common/ErrorDialog.vue';
import Button from './Button.vue';

const {
  changePasswordService,
} = require('@/services/user/user.service');

export default {
  name: 'UpdatePassword',
  components: {
    Button,
    Dialog,
  },
  data() {
    return {
      newPassShow: false,
      popUpType: '',
      newPassword: '',
      error: false,
      errorNewPassMessages: '',
      confirmPassShow: false,
      confirmPassword: '',
      errorConfirm: false,
      errorConfirmPassMessages: '',
      snackbar: false,
      openDialog: false,
      toasterMessage: '',
      timeout: SNACKBAR_TIMEOUT,
    };
  },
  computed: {
    disabled() {
      if (this.newPassword && this.confirmPassword && this.confirmPassword === this.newPassword) {
        return false;
      }
      return true;
    },
  },
  methods: {
    closeDialog() {
      this.snackbar = false;
      this.openDialog = false;
      if (this.popUpType === 'redirectLogin') {
        this.$router.push({ path: '/login' });
      }
    },
    goToSignUp() {
      this.$router.push({ path: '/signup' });
    },
    async emitChangePassword() {
      if (this.newPassword && this.confirmPassword && this.confirmPassword === this.newPassword) {
        this.errorConfirmPassMessages = '';
        try {
          const token = await localStorage.getItem('passToken');
          const resetPasswordResponse = await changePasswordService(
            this.newPassword,
            token,
          );
          if (resetPasswordResponse.status === HTTP_STATUS_CODE.SUCCESS) {
            this.snackbar = true;
            this.openDialog = true;
            this.toasterMessage = TOASTER_MESSAGE.CHANGES_SAVED;
            this.popUpType = 'redirectLogin';
          } else {
            throw new Error();
          }
        } catch (err) {
          this.snackbar = true;
          this.openDialog = true;
          this.toasterMessage = TOASTER_MESSAGE.TROUBLE;
          this.popUpType = '';
        }
      } else {
        this.errorConfirmPassMessages = 'New & Confirm Password do not match';
        this.errorConfirm = true;
        this.popUpType = '';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login-form {
  height: 100%;
  position: relative;
  background-image: url('../assets/Relaxing in hotel-min.jpeg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  .overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
  }
  .form-outline {
    display: flex;
    justify-content: center;
    align-items: center;
    // Styles to customise google login button
    ::v-deep .abcRioButtonContents {
      margin: unset !important;
    }
    ::v-deep .abcRioButtonContentWrapper {
      display: flex;
      justify-content: flex-start;
    }
    .social-media-container {
      padding: 24px;
    }
    .card-color {
      background: #EBECF2;
      box-shadow: none;
      border-top-left-radius: unset !important;
      border-top-right-radius: unset !important;
    }
    .text-filed-container {
      padding: 24px;
      font-size: 16px;
      .password {
        display: flex;
        justify-content: space-between;
      }
      .forget-password{
        color: #4A90E2;
        cursor: pointer;
        font-size:12px;
      }
    }
    .v-card__title {
      padding: 17px 24px;
      box-shadow: inset 0 -1px 0 0 #e7e7e9;
      font-size: 18px;
      font-weight: bold;
    }
    .margin-top-1 {
      margin-top: 1rem;
    }
    .remove-shadow {
      box-shadow: none;
    }
    .facebook-button {
      min-width: 15rem;
      width: 100%;
      color: #fff;
      font-size: 18px;
      font-weight: bold;
      box-sizing: border-box;
      box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 5px 0px;
      padding: 1rem;
      border-radius: 0.25rem;
      background-color: #3c57a4;
      display: flex;
      justify-content: center;
      font-family: sans-serif;
      .facebook-icon {
        margin-right: 1rem;
      }
    }
    .google-button {
      min-width: 15rem;
      width: 100%;
      color: #000;
      font-size: 18px;
      font-weight: bold;
      box-sizing: border-box;
      box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 5px 0px;
      padding: 1rem;
      border-radius: 0.25rem;
      display: flex;
      justify-content: center;
      font-family: sans-serif;
      .google-icon {
        margin-right: 2rem;
      }
    }
  }
}

// responsiveness design
@media only screen and (max-width: 768px) {
  .login-form {
    .v-card {
      min-width: 100% !important;
    }
  }
}
</style>
